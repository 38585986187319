import React from "react";
import "./style.scss";

export const Footer: React.FC = () => {
  return (
    <footer className="footer">
      <div className="content has-text-centered">
        <p>
          <strong>Ever.ninja trace</strong> by <a href="https://github.com/abionics" target="_blank">@Abionics</a> from <a
          href="https://github.com/tonred" target="_blank">TONRED</a> team
          <br/>
            TG chat: <a href="https://t.me/everninja" target="_blank">@everninja</a>
          <br/>
          Please report if you find a bug, but there is no guarantee that it will be solved) Indev 🤗
        </p>
      </div>
    </footer>
  )
}