import React from 'react';
import {Routes, BrowserRouter, Route, Navigate} from 'react-router-dom';

import './styles/main.scss';

// import {Navbar} from './components/Navbar';
import {TraceWorkspace} from './components/TraceWorkspace';
import {createRoot} from "react-dom/client";
import {Footer} from "./components/Footer";

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        {/*<Navbar/>*/}
        <Routes>
          <Route path="/" element={<Navigate to="/trace"/>}/>
          <Route path="/trace" element={<TraceWorkspace/>}/>
        </Routes>
      </BrowserRouter>
      <Footer/>
    </>
  );
};

(async () => {
  const container = document.getElementById('root');
  const root = createRoot(container!);
  root.render(<App/>);
})();
