import React, {useEffect, useState} from "react";
import mermaid from "mermaid";
import CopyToClipboard from "react-copy-to-clipboard";
import {HoverMessage} from "./HoverMessage";

type DiagramProps = {
  content: string,
  dataExplain: any
}


export const DiagramPlot: React.FC<DiagramProps> = ({content, dataExplain}) => {
  const [hoverElement, setHoverElement] = useState<string>('');
  const [hoverPosition, setHoverPosition] = useState<number[]>([0, 0]);

  const [pinnedValue, setPinnedValue] = useState<string>('');
  const [pinnedPosition, setPinnedPosition] = useState<number[]>([0, 0]);
  const closePin = () => {
    setPinnedValue('');
    setPinnedPosition([0, 0]);
  }
  useEffect(() => {
      mermaid.initialize({
        startOnLoad: true,
        maxTextSize: 1e6,
      });
      mermaid.contentLoaded();
      const xpathResult = document.evaluate(
        '//*[contains(@id, "mermaid")]/*[local-name() = "text" and @class="messageText"]',
        document,
        null,
        XPathResult.ORDERED_NODE_ITERATOR_TYPE,
        null)
      let element = xpathResult.iterateNext();
      while (element) {
        element.addEventListener('click', (e) => {
          // @ts-ignore
          setPinnedValue(e.target.nextSibling.nextSibling.innerHTML);
          // @ts-ignore
          setPinnedPosition([e.pageX, e.pageY]);
        });
        element.addEventListener('mouseover', (e) => {
          // @ts-ignore
          setHoverElement(e.target.nextSibling.nextSibling.innerHTML);
          // @ts-ignore
          setHoverPosition([e.pageX, e.pageY]);
        });
        element.addEventListener('mouseout', () => {
          setHoverElement('')
          setHoverPosition([0, 0])
        });
        element = xpathResult.iterateNext();

      }
      document.addEventListener('keydown', (event) => {
        if (event.key === 'Escape') {
          closePin()
        }
      });
    }
    , []);

  const getDataExplain = (element: string): any => {
    return dataExplain[(Number(element) - 1).toString()];
  }

  return (
    <>
      {(pinnedValue) && (
        <HoverMessage dataExplain={getDataExplain(pinnedValue)} cursorPosition={pinnedPosition} onClose={closePin}/>
      )}
      {(hoverElement && !pinnedValue) && (
        <HoverMessage dataExplain={getDataExplain(hoverElement)} cursorPosition={hoverPosition} onClose={closePin}/>
      )}
      <div className="container has-text-centered">
        <CopyToClipboard text={content}>
          <button className="button">Copy Mermaid code</button>
        </CopyToClipboard>
      </div>
      <div className="mermaid">
        {content}
      </div>
    </>
  )
}
