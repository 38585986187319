import React from "react";
import {convertTons} from "../../common";
import ReactJson from 'react-json-view'
import CopyToClipboard from "react-copy-to-clipboard";

type HoverMessageProps = {
  cursorPosition: number[],
  dataExplain: any,
  onClose: () => void
}


export const HoverMessage: React.FC<HoverMessageProps> = ({cursorPosition, dataExplain, onClose}) => {
  return (
    <>
      <article
        className="message"
        style={{
          top: cursorPosition[1],
          left: cursorPosition[0],
          position: "absolute",
          overflow: "auto",
          maxWidth: "70vw",
          zIndex: 999
        }}>
        <div className="message-header">
          <p>
            <CopyToClipboard text={dataExplain.name}>
              <>{dataExplain.name}</>
            </CopyToClipboard>
            <small> call with </small>
            <CopyToClipboard text={convertTons(dataExplain.value ? dataExplain.value : 0)}>
              <>
                {dataExplain.value ? convertTons(dataExplain.value) : 0} EVER
              </>
            </CopyToClipboard>
          </p>
          <button className="delete" aria-label="delete" onClick={onClose}></button>
        </div>
        <div className="message-body">
          <table className="table">
            <tbody>
            <tr>
              <td><em>From:</em></td>
              <CopyToClipboard text={dataExplain.source}>
                <td>{dataExplain.source ? dataExplain.source : 'External'}</td>
              </CopyToClipboard>
            </tr>
            <tr>
              <td><em>To:</em></td>
              <CopyToClipboard text={dataExplain.destination}>
                <td>{dataExplain.destination ? dataExplain.destination : 'External'}</td>
              </CopyToClipboard>
            </tr>
            <tr>
              <td><em>InMsg:</em></td>
              <CopyToClipboard text={dataExplain.message_id}>
                <td>{dataExplain.message_id}</td>
              </CopyToClipboard>
            </tr>
            <tr>
              <td><em>Tx:</em></td>
              <CopyToClipboard text={dataExplain.transaction_id}>
                <td>{dataExplain.transaction_id}</td>
              </CopyToClipboard>
            </tr>
            </tbody>
          </table>
          {dataExplain.data && (
            <>
              <strong>Params:</strong>
              <ReactJson
                src={dataExplain.data}
                groupArraysAfterLength={3}
                displayDataTypes={false}
                quotesOnKeys={false}
                indentWidth={2}
                style={{fontSize: "14px", lineHeight: "16px", wordBreak: 'break-all'}}
              />
            </>
          )}
          <>
            <strong>Transaction details:</strong>
            <ReactJson
              src={{exit_code: dataExplain.exit_code, exit_arg: dataExplain.exit_arg, fees: dataExplain.fees}}
              groupArraysAfterLength={3}
              displayDataTypes={false}
              quotesOnKeys={false}
              indentWidth={2}
              style={{fontSize: "14px", lineHeight: "16px", wordBreak: 'break-all'}}
            />
          </>
          {dataExplain.header && (
            <>
              <strong>Headers:</strong>
              <table className="table">
                <tbody>
                {dataExplain.header.expire && (
                  <tr>
                    <td><em>Expire:</em></td>
                    <CopyToClipboard text={dataExplain.header.expire}>
                      <td>{dataExplain.header.expire}</td>
                    </CopyToClipboard>
                  </tr>
                )}
                {dataExplain.header.time && (
                  <tr>
                    <td><em>Time:</em></td>
                    <CopyToClipboard text={dataExplain.header.time}>
                      <td>{dataExplain.header.time}</td>
                    </CopyToClipboard>
                  </tr>
                )}
                {dataExplain.header.pubkey && (
                  <tr>
                    <td><em>Pubkey:</em></td>
                    <CopyToClipboard text={dataExplain.header.pubkey}>
                      <td style={{overflowWrap: 'break-word'}}>{dataExplain.header.pubkey}</td>
                    </CopyToClipboard>
                  </tr>
                )}
                </tbody>
              </table>
            </>
          )}
        </div>
      </article>
    </>
  )
};