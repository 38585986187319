import React, {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";

import {checkHash} from "../../common"
import "./style.scss";
import classNames from "classnames";
import {DiagramPlot} from "./Diagram";

type ExtractorClass = "tiny" | "extended";

type TraceWorkspaceProps = {
  selectMessageId?: string;
  selectedHighlightAccount?: string;
  selectedPrettifySortTx?: boolean;
  selectedRecognizeAccNames?: boolean;
  selectedIsDevnet?: boolean;
  selectedTargetAccounts?: string;
  selectedIsExtendedExtractor?: boolean;
};

const try_parse_boolean = (value: string, default_value: boolean): boolean => {
  try {
    return Boolean(JSON.parse(value)) as boolean;
  } catch (e) {
    return default_value
  }
}

enum InputTab {
  None,
  Advanced,
  Examples
}

interface TraceServerParams {
  idx: string,
  net: string,
  extractor_class: ExtractorClass,
  target: string | null,
  sort: boolean,
  recognize: boolean,
  highlight: string | null
}

interface TraceServer {
  param: TraceServerParams
  abis: string[]
}

export const TraceWorkspace: React.FC<TraceWorkspaceProps> = ({
                                                                selectMessageId = "",
                                                                selectedHighlightAccount = "",
                                                                selectedTargetAccounts = "",
                                                                selectedPrettifySortTx = false,
                                                                selectedRecognizeAccNames = true,
                                                                selectedIsDevnet = false,
                                                                selectedIsExtendedExtractor = false
                                                              }) => {
  const [searchParams] = useSearchParams();
  if (!selectMessageId && searchParams.has('message_id')) {
    selectMessageId = searchParams.get('message_id') as string
  }
  if (!selectedHighlightAccount && searchParams.has('highlight_address')) {
    selectedHighlightAccount = searchParams.get('highlight_address') as string
  }
  if (!selectedPrettifySortTx && searchParams.has('sort')) {
    selectedPrettifySortTx = try_parse_boolean(searchParams.get('sort') as string, false);
  }
  if (!selectedTargetAccounts && searchParams.has('target_accounts')) {
    selectedTargetAccounts = (searchParams.get('target_accounts') as string);
  }
  if (!selectedRecognizeAccNames && searchParams.has('recognize')) {
    selectedRecognizeAccNames = try_parse_boolean(searchParams.get('recognize') as string, true);
  }
  if (!selectedIsDevnet && searchParams.has('devnet')) {
    selectedIsDevnet = try_parse_boolean(searchParams.get('devnet') as string, false);
  }
  if (!selectedIsExtendedExtractor && searchParams.has('extended_extractor')) {
    selectedIsExtendedExtractor =  try_parse_boolean(searchParams.get('extended_extractor') as string, false);
  }

  const [messageId, setMessageId] = useState<string>(selectMessageId);
  const [highlightAccount, setHighlightAccount] = useState<string>(selectedHighlightAccount);
  const [isExtendedExtractor, setExtendedExtractor] = useState<boolean>(selectedIsExtendedExtractor);
  const [targetAccounts, setTargetAccounts] = useState<string>(selectedTargetAccounts);
  const [prettifySortTx, setPrettifySortTx] = useState<boolean>(selectedPrettifySortTx);
  const [recognizeAccNames, setRecognizeAccNames] = useState<boolean>(selectedRecognizeAccNames);
  const [isDevnet, setIsDevnet] = useState<boolean>(selectedIsDevnet);
  const [error, setError] = useState<string>();
  const [inputTab, setInputTab] = useState<InputTab>(InputTab.None);
  const [loading, setLoading] = useState<boolean>(false);
  const [diagramContent, setDiagramContent] = useState<string>("");
  const [diagramDataExplain, setDiagramDataExplain] = useState<any>();

  useEffect(() => {
    if (!messageId) return;
    if (!checkHash(messageId)) {
      setError('Invalid message ID');
    } else {
      setError(undefined);
    }
  }, [messageId]);

  const drawDiagram = () => {
    setLoading(true);
    setDiagramContent("");
    const url = new URL(`https://api.ever.ninja/trace/server`);
    const param: TraceServerParams = {
      extractor_class: isExtendedExtractor ? 'extended': 'tiny',
      highlight: highlightAccount ? highlightAccount : null,
      net: isDevnet ? 'dev' : 'main',
      recognize: recognizeAccNames,
      sort: prettifySortTx,
      target: targetAccounts ? targetAccounts : null,
      idx: messageId
    }
    const payload: TraceServer = {param, abis: []};
    (async () => {
      try {
        const content = await fetch(url.toString(), {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(payload)
        }).then(res => res.json());
        if (content.exception) {
          setError('Error: ' + content.reason);
        } else {
          setDiagramContent(content.result.diagram);
          setDiagramDataExplain(content.result.data);
        }
      } catch (e) {
        setError('Error while making request');
      }
      setLoading(false);
    })();
  }

  return (
    <section className="section">
      <div className="container is-fluid">
        <div className="columns">
          <div className="column is-1"></div>
          <div className="column is-10">
            <div className="container is-fluid">
              <nav className="panel">
                <p className="panel-heading">
                  Draw diagram
                </p>

                <div className="panel-block">
                  <p className="control has-icons-left">
                    <input
                      className="input"
                      type="text"
                      spellCheck={false}
                      placeholder="Message or Transaction ID"
                      value={messageId}
                      onChange={e => {
                        setMessageId(e.target.value)
                      }}/>
                    <span className="icon is-left">
                      <i className="fas fa-search" aria-hidden="true"></i>
                    </span>
                  </p>
                </div>
                <div className="panel-block">
                  <button
                    className={classNames("button is-primary is-fullwidth", {"is-loading": loading})}
                    onClick={() => drawDiagram()}
                  >
                    Draw
                  </button>
                </div>
                {error != null && (
                  <article className="message is-danger">
                    <div className="message-body">
                      {error}
                    </div>
                  </article>
                )}
                <p className="panel-tabs">
                  <a onClick={() => setInputTab(InputTab.Advanced)}>Advanced</a>
                  <a onClick={() => setInputTab(InputTab.Examples)}>Examples</a>
                  <a onClick={() => setInputTab(InputTab.None)} className="is-active">x</a>
                </p>

                {inputTab === InputTab.Advanced && (
                  <>
                    <label className="panel-block">
                      <input
                        className="input"
                        type="text"
                        spellCheck={false}
                        placeholder="Highlight account"
                        value={highlightAccount}
                        onChange={e => {
                          setHighlightAccount(e.target.value)
                        }}
                      />
                    </label>
                    <label className="panel-block">
                      <input
                        className="input"
                        type="text"
                        spellCheck={false}
                        placeholder="Load messages after initial message from accounts separated by comma"
                        value={targetAccounts}
                        onChange={e => {
                          setTargetAccounts(e.target.value)
                        }}
                      />
                    </label>
                    <label className="panel-block">
                      <input
                        type="checkbox"
                        checked={isDevnet}
                        onChange={() => setIsDevnet(!isDevnet)}
                      />
                      Devnet
                    </label>
                    <label className="panel-block">
                      <input
                        type="checkbox"
                        checked={isExtendedExtractor}
                        onChange={() => setExtendedExtractor(!isExtendedExtractor)}
                      />
                      Extended Extractor
                    </label>
                    <label className="panel-block">
                      <input
                        type="checkbox"
                        checked={recognizeAccNames}
                        onChange={() => setRecognizeAccNames(!recognizeAccNames)}
                      />
                      Recognize account names
                    </label>
                    <label className="panel-block">
                      <input
                        type="checkbox"
                        checked={prettifySortTx}
                        onChange={() => setPrettifySortTx(!prettifySortTx)}
                      />
                      Prettify sort transactions
                    </label>
                    <div className="panel-block">
                      <button
                        className="button is-link is-outlined is-fullwidth"
                        onClick={() => {
                          setTargetAccounts("");
                          setHighlightAccount("");
                          setIsDevnet(false);
                          setRecognizeAccNames(true);
                          setPrettifySortTx(false);
                        }}
                      >
                        Reset all
                      </button>
                    </div>
                  </>
                )}
                {inputTab === InputTab.Examples && (
                  <>
                    <label className="panel-block">
                      <a href="/trace?message_id=1ed6c408710a590c801ad54e295ebdb373c93baa8a9614a4b5ef68f845cab7fe">
                        TIP 3.1 Token Burn
                      </a>
                    </label>
                    <label className="panel-block">
                      <a
                        href="/trace?message_id=a43d2497d42be53b7274db29e35a97ae8a8c0d0f90bca6a24d96ba6fc375f65a&target_accounts=&highlight_address=0%3A771e3d124c7a824d341484718fcf1af03dd4ba1baf280adeb0663bb030ce2bf9&recognize=true">
                        DEX Swap
                      </a>
                    </label>
                    <label className="panel-block">
                      <a
                        href="/trace?message_id=841e6a922430bf7e321372240bfda65fde81558fe79a1889af4c2ba2c055aa96&target_accounts=0%3A442ef2e20ed9569d6eb9d27caea0b4bfe2ee335c00fafa85cd3398b038d58db0&highlight_address=0%3A46d04dc89a2efd94507afd4704f50dccee8c613ef9d80971f20383312750c758&sort=true&recognize=true">
                        Adaever in transfer
                      </a>
                    </label>
                  </>
                )}
              </nav>

            </div>
          </div>
          <div className="column is-1"></div>
        </div>
        <div className="columns">
          <div className="column">
            {diagramContent && (
              <DiagramPlot content={diagramContent} dataExplain={diagramDataExplain}/>
            )}
          </div>
        </div>
      </div>
    </section>
  )
}